import type { AppProps } from 'next/app'
import { ThemeProvider } from 'theme-ui'
import { SessionProvider } from 'next-auth/react'

import { theme } from '../theme'
import '../styles/globals.css'
import { ProtectedArea } from '../components/ProtectedArea';
import { Session } from 'next-auth'

function App({ Component, pageProps: { session, ...pageProps } }: AppProps<{
  session: Session
}>) {
  return (
    <ThemeProvider theme={theme}>
      <SessionProvider session={session}>
        <ProtectedArea>
          <Component {...pageProps} />
        </ProtectedArea>
      </SessionProvider>
    </ThemeProvider>
  )
}

export default App
