import type { Theme } from 'theme-ui'
import { darken } from '@theme-ui/color'

import backgroundImage from '../images/bg.jpg'
import buttonPrimaryBg from '../images/btn-bg-blue.svg'

const readableText = (color: string) => darken(color, 0.65)

export const theme: Theme = {
  fonts: {
    body: '"Sweet Sans Pro", "open-sans", system-ui, sans-serif',
    heading: '"Sweet Sans Pro", "open-sans", sans-serif',
    display: '"CanvasInline-Reg", "turbinado-pro", sans-serif',
  },
  fontSizes: [12, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48],
  fontWeights: {
    body: '400',
    heading: '400',
    bold: '700',
    semibold: '600',
  },
  colors: {
    text: '#105099',
    background: '#fff',
    primary: '#105099',
    success: '#61B05A',
    warning: '#fcd120',
    error: '#B05A5A',
    textMuted: '#435971',
    gold: '#F4D480',
    silver: '#C8CCD0',
    bronze: '#C67E13',
    lightBlue: '#E4F7FB',
  },
  lineHeights: {
    heading: 1.25,
    body: 1.75,
  },
  radii: {
    base: '0.5rem',
    full: '9999px',
  },
  space: [0, 4, 8, 12, 16, 24, 32, 48, 64],
  // Button variants
  buttons: {
    default: {
      fontFamily: 'body',
      borderRadius: 'base',
    },
    primary: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      justifyItems: 'center',
      columnGap: 1,
      minWidth: '290px',
      minHeight: '65px',
      backgroundColor: 'transparent',
      backgroundImage: `url(${buttonPrimaryBg.src})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      color: 'primary',
      cursor: 'pointer',
      fontFamily: 'body',
      fontWeight: 'bold',
      lineHeight: 'heading',
      textTransform: 'uppercase',
    },
    secondary: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      justifyItems: 'center',
      columnGap: 1,
      backgroundColor: 'primary',
      borderRadius: 'base',
      color: 'white',
      cursor: 'pointer',
      fontFamily: 'body',
      // lineHeight: 'heading',
    },
    link: {
      m: 0,
      p: 0,
      background: 'none',
      borderRadius: 'base',
      color: 'inherit',
      fontFamily: 'body',
      textDecoration: 'underline',
    },
    leaderboardSwitch: {
      p: 0,
      backgroundColor: 'transparent',
      color: 'primary',
      cursor: 'pointer',
      fontFamily: 'display',
      fontSize: 5,
    },
    leaderboardSwitchLarge: {
      variant: 'buttons.leaderboardSwitch',
      fontSize: 7,
    },
  },
  // Forms
  forms: {
    input: {
      backgroundColor: 'white',
      color: 'black',
      borderRadius: 8,
    },
    select: {
      borderRadius: 8,
      color: 'black',
    },
    label: {
      mb: 1,
      fontWeight: 'normal',
    },
  },
  // Text variants
  text: {
    heading: {
      fontFamily: 'display',
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
    display: {
      fontFamily: 'display',
    },
    name: {
      variant: 'text.heading',
      fontSize: 10,
    },
    leaderboardListItemRank: {
      width: '2rem',
      height: '2rem',
      p: 2,
      backgroundColor: 'lightBlue',
      borderRadius: 'full',
      fontSize: 0,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    leaderboardListItemRankGold: {
      variant: 'text.leaderboardListItemRank',
      backgroundColor: 'gold',
      color: readableText('gold'),
    },
    leaderboardListItemRankSilver: {
      variant: 'text.leaderboardListItemRank',
      backgroundColor: 'silver',
      color: readableText('silver'),
    },
    leaderboardListItemRankBronze: {
      variant: 'text.leaderboardListItemRank',
      backgroundColor: 'bronze',
      color: readableText('bronze'),
    },
    // profile
    profile: {
      name: {
        fontFamily: 'display',
        fontSize: 7,
        fontWeight: 'normal',
      },
      team: {
        fontWeight: 'semibold',
      },
      handle: {
        color: 'textMuted',
        fontSize: 0,
      },
    },
    // activity
    activity: {
      textLabel: {
        fontFamily: 'display',
        fontSize: 5,
      },
      textValue: {
        fontWeight: 'semibold',
      },
    },
    activityEntry: {
      textLabel: {
        fontFamily: 'display',
        fontSize: 3,
      },
    },
  },
  cards: {
    activity: {
      display: 'grid',
      gap: 1,
      p: 2,
      justifyContent: 'center',
      justifyItems: 'center',
      backgroundColor: 'lightBlue',
      borderRadius: 'base',
    },
  },
  badges: {
    primary: {
      borderRadius: 'base',
    },
  },
  // Layout components
  layout: {
    container: {
      maxWidth: '75rem',
      px: [4, 4, 4, 4, 0],
    },
  },
  // Global styles
  styles: {
    root: {
      m: '0',
      p: '0',
      backgroundImage: `url(${backgroundImage.src})`,
      color: 'text',
      fontFamily: 'body',
    },
  },
}
