import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { Loading } from './Loading';
import { UserProvider } from '../context/UserContext';
import { captureMessage } from '@sentry/nextjs';

export const ProtectedArea = ({children}: { children: any }) => {
  const router = useRouter()
  const session = useSession()

  if (session.status === 'loading') {
    return <Loading />;
  }

  if (session.status === 'unauthenticated') {
    if (! ['/', '/login'].includes(router.pathname)) {
      router.push('/login')
      return <></>
    }

    return children
  }

  if (session.status === 'authenticated' && session.data === null) {
    captureMessage('authenticated and no data!', {
      extra: {
        session: session,
      }
    })

    router.push('/login')
    return <></>
  }

  return (
    <UserProvider token={session.data!.token} user={session.data!.user! as any}>
      {children}
    </UserProvider>
  )
}
