import { Grid } from 'theme-ui'

export const Loading = () => {
  return (
    <Grid
      sx={{
        width: '100%',
        height: '100vh',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
      }}
    >
      Načítání...
    </Grid>
  )
}
